/** @format */

import React, { useState, useRef, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AiOutlineDashboard } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { LuSettings } from "react-icons/lu";
import { FaRegCircleUser } from "react-icons/fa6";
import { MdAddHomeWork } from "react-icons/md";
import { CiLogin } from "react-icons/ci";
import { Layout, theme, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { MdManageAccounts } from "react-icons/md";
import { message } from "antd";
import Login from "../pages/Login/Login";
import { GetAdminProfile } from "../services/Api/Api";
import { IoIosPricetags } from "react-icons/io";
import { MdContacts } from "react-icons/md";
const { Header, Sider, Content } = Layout;

const MainLayout = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [idData, setIdData] = React.useState({});
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const getData = async () => {
		try {
			let result = await GetAdminProfile(localStorage.getItem("adminToken"));
			setIdData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		getData();
	}, []);



	const [collapsed, setCollapsed] = useState(false);
	const {
		token: { colorBgContainer },
	} = theme.useToken();
	const navigate = useNavigate();

	function logout() {
		localStorage.removeItem("adminToken");
		message.success(" Logged Out Successfully");
		setTimeout(() => {
			navigate("/Login");
		}, 1000);
	}

	const token = localStorage.getItem("adminToken");

	return (
		<>
			{token ? (
				<Layout>
					<Sider
						trigger={null}
						collapsible
						collapsed={collapsed}
						className="sidebar"
					>
						<div className="logo">
							<h2 className="text-white fs-5 text-center py-3 mb-0">
								<span className="sm-logo">VRH</span>
								<span className="lg-logo">VACATION RENTAL</span>
							</h2>
						</div>

						<Menu
							theme="dark"
							mode="inline"
							defaultSelectedKeys={[""]}
							onClick={({ key }) => {
								if (key === "signout") {
								} else {
									navigate(key);
								}
							}}
							items={[
								// {
								// 	key: "/",
								// 	icon: <AiOutlineDashboard className="fs-4" />,
								// 	label: "Dashboard",
								// },
								{
									key: "/users",
									icon: <FaUsers className="fs-4" />,
									label: "Users",
								},
								// {
								// 	key: "/properties",
								// 	icon: <MdAddHomeWork className="fs-4" />,
								// 	label: "Property",
								// },
								{
									key: "/payments",
									icon: <IoIosPricetags className="fs-4" />,
									label: "Payments",
								},
								
								{
									key: "/support",
									icon: <MdContacts className="fs-4" />,
									label: "Contact",
								},
							
								{
									key: "/adminList",
									icon: <MdManageAccounts className="fs-4" />,
									label: "Access Management",
								},
							]}
						/>
					</Sider>
					<Layout className="site-layout">
						<Header
							className="d-flex justify-content-between ps-1 pe-5"
							style={{
								padding: 0,
								background: colorBgContainer,
							}}
						>
							{React.createElement(
								collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
								{
									className: "trigger",
									onClick: () => setCollapsed(!collapsed),
								}
							)}
							<div className="d-flex gap-4 align-items-center">
								{/* <div className="position-relative clicked">
									<FiMail className="fs-3" onClick={handleMailClick} />
								</div> */}

								<div className="vertical-line"></div>
								<div className="position-relative">
									<div className="d-flex align-items-center">
										<div
											role="button"
											id="dropdownMenuLink"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											className="d-flex align-items-center"
										>
											<FaRegCircleUser className="fs-3 me-2" />
											<div className="d-flex flex-column">
												{" "}
												<p className="mb-0" style={{ fontWeight: "700" }}>
													{idData?.name}
												</p>
												<p className="mb-0">{idData?.admin_role?.name || ""}</p>
											</div>
										</div>
										<div
											className="dropdown-menu admin"
											aria-labelledby="dropdownMenuLink"
											style={{
												// margin: "10px !important",
												borderTopColor: "purple",
												borderTopWidth: "4px",
											}}
										>
											<li to="/viewAdmin">
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													to="/viewAdmin"
												>
													<FiUser style={{ marginRight: "10px" }} />
													View Profile
												</Link>
											</li>
											<li>
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													to="/reset-password"
												>
													<LuSettings style={{ marginRight: "10px" }} />
													Change Password
												</Link>
											</li>
											{/* <li>
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													to="/loginLogs"
												>
													<FiActivity style={{ marginRight: "10px" }} />
													User Login Activity
												</Link>
											</li> */}
											<div className="dropdown-divider"></div>
											<li>
												<Link
													className="dropdown-item py-1 mb-1"
													style={{ height: "auto", lineHeight: "30px" }}
													onClick={() => logout()}
												>
													<CiLogin style={{ marginRight: "10px" }} />
													Sign Out
												</Link>
											</li>
										</div>
									</div>
								</div>
							</div>
						</Header>
						<Content
							style={{
								margin: "24px 16px",
								padding: 24,
								minHeight: 280,
								background: colorBgContainer,
							}}
						>
							<ToastContainer
								position="top-right"
								autoClose={250}
								hideProgressBar={false}
								newestOnTop={true}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								theme="light"
							/>
							<Outlet />
						</Content>
					</Layout>
				</Layout>
			) : (
				<Login />
			)}
		</>
	);
};
export default MainLayout;
